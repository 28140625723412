@import "../../constants/palette";

.BorderBox {
  padding: 4em 3vmax 3vmax 3vmax;
  position: relative;

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .Title {
    background-color: black;
    color: rgba($primary, 0.55);
    font-weight: 800;
    text-align: center;
    position: absolute;
    top: -2vmax;
    padding: 0 20px;
  }
}

.MyThumbnail {
  max-width: 20em;
  max-height: 20em;
  border-radius: 50%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .SvgTriangle {
    position: absolute;
    right: 15px;
    top: -10px;
    overflow: visible;
    z-index: 10;
    transform: rotate(15deg);
  }

  .SvgCircle {
    position: absolute;
    left: 20px;
    bottom: 0;
    overflow: visible;
    z-index: 10;
  }
}
