.Hero {
  position: relative;
  height: 100vh;
  overflow: hidden;
  padding-top: 70px;

  .PhotoContainer {
    img {
      // image-rendering: pixelated;
      object-fit: contain;
      object-position: bottom;
      height: 90vmin;
      width: auto;
      z-index: 3;
    }
  }

  //#tsparticles {
  //  width: 100%;
  //  height: 100%;
  //  position: absolute;
  //  margin: 0;
  //  padding: 0;
  //  top: 0;
  //  left: 0;
  //}
}

.BackgroundCircle {
  background-color: #e9456046;
  width: 80vmin;
  max-width: 90%;
  height: 80vmin;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  z-index: -1;
}

.Particles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: -2;
}