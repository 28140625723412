.ProjectCardFirst {
  z-index: 3;
  position: relative;
  background-color: #222222;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.ProjectCardMiddle {
  z-index: 3;
  position: relative;
  background-color: #222222;
  border-radius: 10px;
  transition: border-radius 1s;
}
.ProjectCardLast {
  z-index: 3;
  position: relative;
  background-color: #222222;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
}
.ProjectCardBg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;
  margin: 0 auto;
  background-position: center -100px;
  -webkit-filter: blur(7px);
}