.Hobbies {
  .photography {
    position: relative;
  }
  .photography:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      pointer-events: none;
      background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1) 80%);
      width: 100%;
      height: 400px;
  }
  .view-more {
    position: relative;
    top: -50px;
    z-index: 2;
  }
}